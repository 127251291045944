import React,{useState} from 'react'
import Carrera from './Carrera';
import { MDBListGroup, MDBListGroupItem, MDBBadge } from 'mdb-react-ui-kit';
const Cienciamania = () => {
  const[carreras, setCarreras] = useState(
    [
        {
            id: 1,
            name: "TECNICATURA EN ADMINISTRACIÓN DE EMPRESAS",
            años: "3"
        },
        {
          id: 2,
          name: "TECNICATURA EN ANALISTA EN SISTEMAS",
          años: "3"
        },
        {
            id: 3,
            name: "TECNICATURA EN RECURSOS HUMANOS",
            años: "3"
          },
          {
            id: 4,
            name: "TECNICATURA EN MARKETING DIGITAL",
            años: "3"
          },
          {
            id: 5,
            name: "CURSOS de capacitación, formación y perfeccionamiento",
            años: "-"
          },
 
    ]
);

return (
<>
    <div className='row'>
        {carreras.map((carrera) => {
            return(
                <MDBListGroup style={{ minWidth: '22rem' }}>
                    <MDBListGroupItem className='d-flex justify-content-between align-items-center'>
                        
                        <Carrera key={carrera.id} name={carrera.name}/>
                        
                        <MDBBadge pill>{carrera.años} años</MDBBadge>
                    </MDBListGroupItem>
                    
                </MDBListGroup>
            );
        })}
        <a href='' className='mt-3'></a>
    </div>
</>
  
)
}
export default Cienciamania