import React,{useState} from 'react'
import Carrera from './Carrera';
import { MDBListGroup, MDBListGroupItem, MDBBadge } from 'mdb-react-ui-kit';
const EchoVictor = () => {
  const[carreras, setCarreras] = useState(
    [
        {
          id: 1,
          name: "Piloto Privado de Avión y Helicóptero",
          años: "-"
        },
        {
          id: 2,
          name: "Piloto Comercial de Avión y Helicóptero",
          años: "-"
        },
        {
          id: 3,
          name: "Piloto Aeroaplicador de Avión",
          años: "-"
        },
        {
          id: 4,
          name: "Instructor de Vuelo de Avión y Helicópter",
          años: "-"
        },     
    ]
);

return (
<>
    <div className='row'>
        {carreras.map((carrera) => {
            return(
                <MDBListGroup style={{ minWidth: '22rem' }}>
                    <MDBListGroupItem className='d-flex justify-content-between align-items-center'>
                        
                        <Carrera key={carrera.id} name={carrera.name}/>
                        
                        <MDBBadge pill>{carrera.años} años</MDBBadge>
                    </MDBListGroupItem>
                    
                </MDBListGroup>
            );
        })}
        <a href='https://www.grupoadibv.com/#!/-escuela-de-vuelo/' className='mt-3'>Sitio Web</a>
    </div>
</>
  
)
}
export default EchoVictor