import React,{useState} from 'react'
import Carrera from './Carrera';
import { MDBListGroup, MDBListGroupItem, MDBBadge } from 'mdb-react-ui-kit';


const UNVM = () => {
  const[carreras, setCarreras] = useState(
    [
        {
            id: 1,
            name: "Ingeniería en Alimentos",
            años: "-"
        },
        {
            id: 2,
            name: "Agronomía",
            años: "-"
        },
        {
            id: 3,
            name: "Medicina Veterinaria",
            años: "-"
        },
        {
            id: 4,
            name: "Licenciatura en Ambiente y Energías Renovables",
            años: "-"
        },
        {
            id: 5,
            name: "Diseño Industrial",
            años: "-"
        },
        {
            id: 6,
            name: "Tecnicatura Universitaria: “Interpretación de Lengua de Señas Argentina- español”",
            años: "-"
        },
        {
            id: 7,
            name: "Licenciatura en Diseño y Producción Audiovisual",
            años: "-"
        },
        {
            id: 8,
            name: "Licenciatura en Composición Musical con Orientación en Música Popular",
            años: "-"
        },
        {
            id: 9,
            name: "Licenciatura en Ciencias de la Educación",
            años: "-"
        },
        {
            id: 10,
            name: "Licenciatura en Educación Física",
            años: "-"
        },
        {
            id: 11,
            name: "Licenciatura en Psicopedagogía",
            años: "-"
        },
        {
            id: 12,
            name: "Profesorado en Matemática",
            años: "-"
        },
        {
            id: 13,
            name: "Profesorado en Lengua y Literatura",
            años: "-"
        },
        {
            id: 14,
            name: "Licenciatura en Lengua y Literatura",
            años: "-"
        },
        {
            id: 15,
            name: "Profesorado en Lengua Inglesa",
            años: "-"
        },
        {
            id: 16,
            name: "Licenciatura en Terapia Ocupacional",
            años: "-"
        },
        {
            id: 17,
            name: "Licenciatura en Enfermería",
            años: "-"
        },
        {
            id: 18,
            name: "Medicina",
            años: " -"
        },
        {
            id: 19,
            name: "Instituto Académico Pedagógico de Ciencias Sociales",
            años: "-"
        },
        {
            id: 20,
            name: "Pregrado",
            años: "-"
        },
        {
            id: 21,
            name: "Tecnicatura Superior en Administración y Gestión de Instituciones Universitarias",
            años: "-"
        },
        {
            id: 22,
            name: "Tecnicatura Universitaria en Administración",
            años: "-"
        },
        {
            id: 23,
            name: "Tecnicatura Universitaria en Contabilidad",
            años: "-"
        },
        {
            id: 24,
            name: "Licenciatura en Sociología ",
            años: "-"
        },
        {
            id: 25,
            name: "Licenciatura en Desarrollo Local-Regional",
            años: "-"
        },
        {
            id: 26,
            name: "Licenciatura en Ciencia Política",
            años: "-"
        },
        {
            id: 27,
            name: "Contador Público",
            años: "-"
        },
        {
            id: 28,
            name: "Licenciatura en Economía",
            años: "-"
        },
        {
            id: 29,
            name: "Licenciatura en Administración",
            años: "-"
        },
        {
            id: 30,
            name: "Licenciatura en Ciencias de la Comunicación",
            años: "-"
        },
        {
            id: 31,
            name: "Licenciatura en Comunicación Social",
            años: "-"
        },
        {
            id: 32,
            name: "Licenciatura en Trabajo Social",
            años: "-"
        },
        {
            id: 33,
            name: "Licenciatura en Seguridad",
            años: "-"
        },
        {
            id: 34,
            name: "Licenciatura en Comercio Internacional",
            años: "-"
        },
    ]
);

return (
<>
    <div className='row'>
        {carreras.map((carrera) => {
            return(
                <MDBListGroup style={{ minWidth: '22rem' }}>
                    <MDBListGroupItem className='d-flex justify-content-between align-items-center'>
                        
                        <Carrera key={carrera.id} name={carrera.name}/>
                        
                        <MDBBadge pill>{carrera.años} años</MDBBadge>
                    </MDBListGroupItem>
                    
                </MDBListGroup>
            );
        })}
        <a href='https://www.unvm.edu.ar/' className='mt-3'>Sitio Web</a>
    </div>
</>
  
)
}

export default UNVM