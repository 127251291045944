import React from 'react'
import '../Componentes/Tabs.css';
import { useState } from "react";
import SigloXXI from './SigloXXI';

const TabSigloXXI = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
      setToggleState(index);
    };
  
    return (
      <div className="container">
        <div className="bloc-tabs">
          <button
            className={toggleState === 1 ? "tabs active-tabs" : "tabs btn btn-primary"}
            onClick={() => toggleTab(1)}
          >
            CARRERAS
          </button>
          <button
            className={toggleState === 2 ? "tabs active-tabs" : "tabs btn btn-primary"}
            onClick={() => toggleTab(2)}
          >
              MAS INFORMACIÓN
          </button>
         
        </div>
  
        <div className="content-tabs">
          <div
            className={toggleState === 1 ? "content  active-content" : "content"}
          >
            <h2>Carreras Universitarias</h2>
          
            <div className='listaCarreras'>
              <SigloXXI />
            </div>
          </div>
  
          <div
            className={toggleState === 2 ? "content  active-content" : "content"}
          >
            <h2>Ingresar para ver planes de estudio</h2>
            <a href='https://www.ies21.edu.ar/carreras/' className='btn btn-success'>VISITAR SITIO WEB</a>
  
          </div>
         
        </div>
      </div>
    );
}

export default TabSigloXXI;


