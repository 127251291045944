import React from 'react'

const InstDelRosario = () => {
  return (
    <div className='row'>
            <a href='https://www.idelr.edu.ar/carreras-list'>Ver las carreras en la pagina oficial.</a>
    </div>
  )
}

export default InstDelRosario