import React from 'react'

const BlasPascalBV = () => {
  return (
    <div className='row'>
            <a href='https://redpascal.com.ar/'>Ver las carreras en la pagina oficial.</a>
        </div>
  )
}

export default BlasPascalBV