import React,{useState} from 'react'
import Carrera from './Carrera';
import { MDBListGroup, MDBListGroupItem, MDBBadge } from 'mdb-react-ui-kit';
const Velez = () => {
  const[carreras, setCarreras] = useState(
    [
        {
            id: 1,
            name: "Profesorado de Educación Inicial",
            años: "4"
        },
        {
          id: 2,
          name: "Profesorado de Educación Primaria",
          años: "4"
        },
 
    ]
);

return (
<>
    <div className='row'>
        {carreras.map((carrera) => {
            return(
                <MDBListGroup style={{ minWidth: '22rem' }}>
                    <MDBListGroupItem className='d-flex justify-content-between align-items-center'>
                        
                        <Carrera key={carrera.id} name={carrera.name}/>
                        
                        <MDBBadge pill>{carrera.años} años</MDBBadge>
                    </MDBListGroupItem>
                    
                </MDBListGroup>
            );
        })}
        <a href='https://www.instagram.com/ipdvs/?igshid=17rzx8sxgd95a' className='mt-3'>Mas Info</a>
    </div>
</>
  
)
}

export default Velez