import React from 'react'

const ColegioRegional = () => {
  return (
    <div className='row'>
        <a href='https://fi.iua.edu.ar/'>Ver las carreras en la pagina oficial.</a>
    </div>
  )
}

export default ColegioRegional