import React,{useState} from 'react'
import Carrera from './Carrera';
import { MDBListGroup, MDBListGroupItem, MDBBadge } from 'mdb-react-ui-kit';

const InstSuperiorVictor = () => {
 
    const[carreras, setCarreras] = useState(
      [
          {
              id: 1,
              name: "Profesorado De Educación Inicial",
              años: "4"
          },
          {
              id: 2,
              name: "Profesorado De Educación Primaria ",
              años: "4"
          },
          {
              id: 3,
              name: "Profesorado De Educación Especial ",
              años: "4"
          },
          {
              id: 4,
              name: "Profesorado De Biología ",
              años: "4"
          },
          {
              id: 5,
              name: "Psicopedagogía",
              años: "4"
          },
          {
              id: 6,
              name: "Tecnicatura Superior En Óptica",
              años: "3"
          },
          {
              id: 7,
              name: "Tecnicatura Superior En Radiología",
              años: "3"
          },
          {
              id: 8,
              name: "Tecnicatura Superior En Laboratorio",
              años: "3"
          },
          {
              id: 9,
              name: "Tecnicatura Superior En Traducción De Inglés",
              años: "3"
          },        
      ]
  );

  return (
  <>
      <div className='row'>
          {carreras.map((carrera) => {
              return(
                  <MDBListGroup style={{ minWidth: '22rem' }}>
                      <MDBListGroupItem className='d-flex justify-content-between align-items-center'>
                          
                          <Carrera key={carrera.id} name={carrera.name}/>
                          
                          <MDBBadge pill>{carrera.años} años</MDBBadge>
                      </MDBListGroupItem>
                      
                  </MDBListGroup>
              );
          })}
          <a href='https://www.bibliotecarivadavia.edu.ar/nivel-superior-instituto-superior-victor-mercante/' className='mt-3'>Link para mas info</a>
      </div>
  </>
    
  )
  
}

export default InstSuperiorVictor