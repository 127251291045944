import React,{useState} from 'react'
import Carrera from './Carrera';
import { MDBListGroup, MDBListGroupItem, MDBBadge } from 'mdb-react-ui-kit';

const UTNVM = () => {
  const[carreras, setCarreras] = useState(
    [
        {
            id: 1,
            name: "Ingeniería en Sistemas de Información",
            años: "-"
        },
        {
            id: 2,
            name: "Ingeniería Electrónica",
            años: "-"
        },
        {
            id: 3,
            name: "Ingeniería Mecánica",
            años: "-"
        },
        {
            id: 4,
            name: "Ingeniería Química",
            años: "-"
        },
        {
            id: 5,
            name: "Licenciatura en Administración Rural",
            años: "-"
        },
        {
            id: 6,
            name: "Tecnicatura Universitaria en Mecatrónica",
            años: "-"
        },
        {
            id: 7,
            name: "Tecnicatura Universitaria en Negoc. de Bienes",
            años: "-"
        },
        {
            id: 8,
            name: "Tecnicatura Universitaria en Logística",
            años: "-"
        },
        {
            id: 9,
            name: "Tecnicatura Universitaria en Material Rodante Ferroviario",
            años: "-"
        },
        {
            id: 10,
            name: "LLicenciatura en Tecnología Educativa",
            años: "-"
        },
        {
            id: 11,
            name: "Licenciatura en Lengua Inglesa",
            años: "-"
        },
        {
            id: 12,
            name: "Licenciatura en Higiene y Seguridad",
            años: "-"
        },
        {
            id: 13,
            name: "Licenciatura en Ciencias Aplicadas",
            años: "-"
        },
       
    ]
);

return (
<>
    <div className='row'>
        {carreras.map((carrera) => {
            return(
                <MDBListGroup style={{ minWidth: '22rem' }}>
                    <MDBListGroupItem className='d-flex justify-content-between align-items-center'>
                        
                        <Carrera key={carrera.id} name={carrera.name}/>
                        
                        <MDBBadge pill>{carrera.años} años</MDBBadge>
                    </MDBListGroupItem>
                    
                </MDBListGroup>
            );
        })}
        <a href='https://www.frvm.utn.edu.ar/#/' className='mt-3'>Sitio Web</a>
    </div>
</>
  
)
}

export default UTNVM