import React from 'react'

const Catolica = () => {
  return (
    <div className='row'>
        <a href='https://www.ucc.edu.ar/carreras/'>Ver las carreras en la pagina oficial.</a>
    </div>
  )
}

export default Catolica