import React,{useState} from 'react'
import Carrera from './Carrera';
import { MDBListGroup, MDBListGroupItem, MDBBadge } from 'mdb-react-ui-kit';
const MMoreno = () => {
  const[carreras, setCarreras] = useState(
    [
        {
            id: 1,
            name: "Profesorado de Educación Secundaria en Biología",
            años: "4"
        },
        {
          id: 2,
          name: "Profesorado de Educación Secundaria en Economía",
          años: "4"
        },
        {
          id: 3,
          name: "Profesorado de Educación Secundaria en Física",
          años: "4"
        },
        {
          id: 4,
          name: "Profesorado de Educación Secundaria en Geografía",
          años: "4"
        },
        {
          id: 5,
          name: "Profesorado de Educación Secundaria en Historia",
          años: "4"
        },
        {
          id: 6,
          name: "Profesorado de Educación Secundaria en Matemática",
          años: "4"
        },
        {
          id: 7,
          name: "Profesorado de Educación Secundaria en Química",
          años: "4"
        },
        {
          id: 8,
          name: "Profesorado de Educación Secundaria en Lengua y Literatura",
          años: "4"
        },
        {
          id: 9,
          name: "Profesorado de Educación Inicial",
          años: "4"
        },
        {
          id: 10,
          name: "Profesorado de Educación Especial con orientación en Discapacidad Intelectual",
          años: "4"
        },

        
    ]
);

return (
<>
    <div className='row'>
        {carreras.map((carrera) => {
            return(
                <MDBListGroup style={{ minWidth: '22rem' }}>
                    <MDBListGroupItem className='d-flex justify-content-between align-items-center'>
                        
                        <Carrera key={carrera.id} name={carrera.name}/>
                        
                        <MDBBadge pill>{carrera.años} años</MDBBadge>
                    </MDBListGroupItem>
                    
                </MDBListGroup>
            );
        })}
        <a href='https://ifdmoreno-cba.infd.edu.ar/sitio/' className='mt-3'>Sitio Web</a>
    </div>
</>
  
)
}
export default MMoreno