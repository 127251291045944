import React,{useState} from 'react'
import Carrera from './Carrera';
import { MDBListGroup, MDBListGroupItem, MDBBadge } from 'mdb-react-ui-kit';
const Siglo21MB = () => {
  const[carreras, setCarreras] = useState(
    [
        {
            id: 1,
            name: "Profesorado de Educación Primaria",
            años: "4"
        },
        
    ]
);

return (
<>
    <div className='row'>
      
        <a href='https://21.edu.ar/' className='mt-3'>Mas Info</a>
    </div>
</>
  
)
}

export default Siglo21MB