import React,{useState} from 'react'
import Carrera from './Carrera';
import { MDBListGroup, MDBListGroupItem, MDBBadge } from 'mdb-react-ui-kit';
const EscuelaLecheriaVM = () => {
  const[carreras, setCarreras] = useState(
    [
        {
            id: 1,
            name: "Tecnicatura Superior en Gestión de la Producción Agropecuaria",
            años: "-"
        },
        {
            id: 2,
            name: "Tecnicatura Superior en Industrias Lácteas",
            años: "-"
        },
        {
            id: 3,
            name: "Tecnicatura Superior en Lechería y Tecnología de Alimentos",
            años: "-"
        },
    ]
);

return (
<>
    <div className='row'>
        {carreras.map((carrera) => {
            return(
                <MDBListGroup style={{ minWidth: '22rem' }}>
                    <MDBListGroupItem className='d-flex justify-content-between align-items-center'>
                        
                        <Carrera key={carrera.id} name={carrera.name}/>
                        
                        <MDBBadge pill>{carrera.años} años</MDBBadge>
                    </MDBListGroupItem>
                    
                </MDBListGroup>
            );
        })}
        <a href='https://escueladelecheria.edu.ar/novedad/inicio' className='mt-3'>Sitio Web</a>
    </div>
</>
  
)
}

export default EscuelaLecheriaVM