import React from 'react'
const Carrera = (props) => {
  return (
    <div>
        <p >{props.name}</p>
        <p>{props.años}</p>
    </div>
  )
}

export default Carrera