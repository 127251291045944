import React from 'react'
import './CardCarrera.css'
import CatLaw from '../CatLaw.jsx'
const CardCarrera = () => {
  return (
    <>

        
    </>
  )
}

export default CardCarrera