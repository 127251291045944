
import { Navbar, Nav, Container } from 'react-bootstrap'
import { Outlet, Link } from 'react-router-dom'
import '../Layouts/Navbar.css';
const NavbarMain = () => {
  
  return (
    <>


        <section>

          <Outlet>

          </Outlet>

        </section>

        
    </>
  )
}

export default NavbarMain