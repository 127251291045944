import React,{useState} from 'react'
import Carrera from './Carrera';
import { MDBListGroup, MDBListGroupItem, MDBBadge } from 'mdb-react-ui-kit';

const EducacionFisicaBVille = () => {
  const[carreras, setCarreras] = useState(
    [
        {
            id: 1,
            name: "Profesorado de Educación Física - Título Oficial Validez Nacional",
            años: "4"
        },
    ]
);

return (
<>
    <div className='row'>
        {carreras.map((carrera) => {
            return(
                <MDBListGroup style={{ minWidth: '22rem' }}>
                    <MDBListGroupItem className='d-flex justify-content-between align-items-center'>
                        
                        <Carrera key={carrera.id} name={carrera.name}/>
                        
                        <MDBBadge pill>{carrera.años} años</MDBBadge>
                    </MDBListGroupItem>
                    
                </MDBListGroup>
            );
        })}
        <a href='http://www.isef.edu.ar/wp/' className='mt-3'>Sitio Web</a>
    </div>
</>
  
)
}

export default EducacionFisicaBVille