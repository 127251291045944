import React from 'react'
import './CardCarrera.css'
import CatIng from '../CatIng.jsx'
const CardCarreraIng = () => {
  return (
    <>
        <div className="card cardCarrera text-center " style={{background: "linear-gradient(89.97deg, #12998e 1.84%, #305F31 102.67%)"}} >
            <CatIng />
            <h1 className='tituloCardCarrera text-white'>Ingenieria en Sistemas</h1>
            
        </div>
    </>
  )
}

export default CardCarreraIng