import React from 'react'
import Tabs from './Tabs';
import '../Componentes/Main.css';
import { Accordion} from 'react-bootstrap';
import Cover from './cover/Cover.js';
import TabUTN from './TabUTN';
import TabCervantes from './TabCervantes';
import TabColegioRegional from './TabColegioRegional';
import TabBlasPascal from'./TabBlasPascal'  
import TabCatolica from './TabCatolica';
import TabSigloXXI from './TabSigloXXI';
import TabUNVM from './TabUNVM';
import TabInstDelRosario from'./TabInstDelRosario'
import TabInstSuperiorVictor from'./TabInstSuperiorVictor'
import TabColegioProvincial from './TabColegioProvincial';
import TabEduFisicaBVille from './TabEduFisicaBVille'
import TabBPascalBV from './TabBPascalBV'
import TabEchoVictor from './TabEchoVictor'
import TabMMoreno from './TabMMoreno'
import TabPolivalente from './TabPolivalente'
import TabVelez from './TabVelez'
import TabCienciamania from './TabCienciamania'
import TabIciecJP from './TabIciecJP'
import TabVirgen from './TabVirgen'
import TabConservatorioBVille from './TabConservatorioBVille'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingColumns } from "@fortawesome/free-solid-svg-icons";
import imagenExpo from '../imagenes/expo2022.png'
import imagenMuni from '../imagenes/muni.png'
import banner from '../imagenes/banner.jpeg'
import imagenForo from '../imagenes/forodlj.png'
import TabUTNVM from './TabUTNVM';
import TabEscuelaLecheriaVM from'./TabEscuelaLecheriaVM';
import TabSiglo21MB from './TabSiglo21MB';
import Propi from './Propi';
import LottieUni from './LottieUni';
import OtroLottie from './OtroLottie';
import CardCarrera from './cardCarreras/CardCarrera.jsx';
import CardCarreraIng  from './cardCarreras/CardCarreraIng.jsx'
import LottieSaludo from './LottieSaludo';
import Banner1 from '../imagenes/banner1.mp4'
import ReactPlayer from 'react-player'
import LottieInvitados from './LottieInvitados';

const Main = () => {

  return (
    <div style={{background: "linear-gradient(89.97deg, #2F8048 1.84%, #305F31 102.67%)"}}>
      <Cover />
      {/*EXPO LOGO*/}
      <div className='container-fluid bg-white' >
        <div className='IMGEXPO text-center'>
          <img src={imagenExpo} className='imgHeader' alt='Expo Carreras 2022'></img> 
        </div>
      </div>
      {/*COMUNICADO*/}
      <div className='container cont text-center'>
        <div className='row'>
          <div className='col-lg-6 col-sm-12 p-3'>
            <Propi />
          </div>
          <div className='col-lg-6 col-sm-12 mt-5 p-5 text-white'>
            <h3 className='text-black'>Comunicado Oficial</h3>
            <hr></hr>
            Aca va todo el texto que nos gustaria poner Aca va todo el texto que nos gustaria poner
            Aca va todo el texto que nos gustaria poner Aca va todo el texto que nos gustaria poner
            Aca va todo el texto que nos gustaria poner Aca va todo el texto que nos gustaria poner
            Aca va todo el texto que nos gustaria poner Aca va todo el texto que nos gustaria poner
            Aca va todo el texto que nos gustaria poner Aca va todo el texto que nos gustaria poner
            Aca va todo el texto que nos gustaria poner Aca va todo el texto que nos gustaria poner
          </div>
        </div>
      
      </div>
      {/*MAIN*/}
      <div className='container-fluid bg-white' >
        <div className="row text-center">
          <h2 className='text-center mt-5 text-black'>I N V I T A D O S</h2>
          <h2 className='text-center mt-5 text-black'>E S P E C I A L E S</h2>
          <div className="col-sm-12 col-lg-4 text-center ">
            <video className='v1' width="300" controls muted autoPlay>
              <source src={Banner1} type="video/mp4"/>
              Tu navegador no soporta este formato.
            </video>
          </div>
          <div className="col-sm-12 col-lg-4 mt-5 text-center">
            <LottieInvitados />
          </div>
          <div className="col-sm-12 col-lg-4 text-center">
            <img src={banner} className='imgBanner mb-5' alt='Expo Carreras 2022'></img>
          </div>
        </div>
      </div>
        <LottieSaludo />
        <h1 className='text-center text-white'>Bienvenido a la Expo Carrera 2022 Virtual</h1>
        
        <p className='text-center m-5 text-white'>Navegá en este menu interactivo para revisar las carreras de cada universidad o instituto</p>

        <div className="container mb-5">
          
              <CardCarrera />
            
            
        </div>
      <div className='container' >
      
        {/*CORDOBA*/}
        <div className="card text-center">
          <div className="card-header head1 text-white" style={{background: "linear-gradient(89.97deg, #0070D4 1.84%, #0D303C 102.67%)"}}>
            <LottieUni />
            <strong>CORDOBA</strong>
            </div>
            <div className="card-body" style={{background: "linear-gradient(89.97deg, #0070D4 1.84%, #0D303C 102.67%)"}}>
              
              <div className='col-12'>
                <p>
                  <a className="btn btn-primary" data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">Ver Universidades</a>
                </p>
                <div className="row" style={{background: "linear-gradient(89.97deg, #0070D4 1.84%, #0D303C 102.67%)"}}>
                  <div className="col-12">
                    <div className="collapse multi-collapse" id="multiCollapseExample1">
                      <div className="col-12" style={{background: "linear-gradient(89.97deg, #0070D4 1.84%, #0D303C 102.67%)"}}>
                        <div class="text-center">
                          <Accordion className='col-12'>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header className='u1'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>UNIVERSIDAD NACIONAL DE CORDOBA</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <Tabs />

                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header className='u1'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>COLEGIO UNIVERSITARIO IES SIGLO 21</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabSigloXXI />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header className='u1'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>UNIVERSIDAD TECNOLÓGICA NACIONAL -FACULTAD REGIONAL CÓRDOBA</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabUTN />

                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header className='u1'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>INSTITUTO CERVANTES</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabCervantes />

                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header className='u1'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>CENTRO REGIONAL UNIVERSITARIO CÓRDOBA IUA</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabColegioRegional />

                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="5">
                            <Accordion.Header className='u1'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>UNIVERSIDAD CATÓLICA DE CÓRDOBA (UCC)</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabCatolica />

                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="6">
                            <Accordion.Header className='u1'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>UNIVERSIDAD BLAS PASCAL</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabBlasPascal />

                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="7">
                            <Accordion.Header className='u1'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>UNIVERSIDAD PROVINCIAL DE CÓRDOBA (UPC)</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabColegioProvincial />

                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>   
          </div>
         
        </div>
        {/*VILLA MARIA*/}
        <div className="card text-center">
          <div className="card-header head2 text-white" style={{background: "linear-gradient(89.97deg, #FFC107 1.84%, #0D303C 102.67%)"}}>
            <LottieUni />
            <strong>VILLA MARIA</strong>
            </div>
            <div className="card-body" style={{background: "linear-gradient(89.97deg, #FFC107 1.84%, #0D303C 102.67%)"}}>
              
              <div className='col-12'>
                <p>
                  <a class="btn btn-warning" data-bs-toggle="collapse" href="#multiCollapseExample2" role="button" aria-expanded="false" aria-controls="multiCollapseExample2">Ver Universidades</a>
                </p>
                <div class="row">
                  <div class="col-12">
                    <div class="collapse multi-collapse" id="multiCollapseExample2">
                      <div className=" col-12">
                        <div class="text-center">
                          <Accordion className='col-12'>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header className='u2'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>UNIVERSIDAD NACIONAL DE VILLA MARÍA</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabUNVM />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header className='u2'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>UNIVERSIDAD TECNOLÓGICA NACIONAL (UTN FRVM)</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabUTNVM />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header className='u2'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/> ESCUELA SUPERIOR INTEGRAL DE LECHERÍA</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabEscuelaLecheriaVM />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header className='u2'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>INSTITUTO DEL ROSARIO- PROF. GABRIELA MISTRAL</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabInstDelRosario />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header className='u2'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>INSTITUTO SUPERIOR VÍCTOR MERCANTE</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabInstSuperiorVictor />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>   
          </div>
          
        </div>
        {/*BELL VILLE*/}
        <div className="card text-center">
          <div className="card-header head3 text-white" style={{background: "linear-gradient(89.97deg, #E60404 1.84%, #0D303C 102.67%)"}}>
            <LottieUni />
            <strong>BELL VILLE</strong>
            </div>
            <div className="card-body" style={{background: "linear-gradient(89.97deg, #E60404 1.84%, #0D303C 102.67%)"}}>
              
              <div className='col-12'>
                <p>
                  <a class="btn btn-danger" data-bs-toggle="collapse" href="#multiCollapseExample3" role="button" aria-expanded="false" aria-controls="multiCollapseExample3">Ver Instituciones</a>
                </p>
                <div class="row">
                  <div class="col-12">
                    <div class="collapse multi-collapse" id="multiCollapseExample3">
                      <div className="col-12">
                        <div class="text-center">
                          <Accordion className='col-12'>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header className='u3'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>INSTITUTO SUPERIOR DE EDUCACIÓN FÍSICA BELL</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabEduFisicaBVille />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header className='u3'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>CONSERVATORIO SUPERIOR DE MÚSICA GILARDO GILARDI</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabConservatorioBVille />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header className='u3'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>CENTRO SUPERIOR POLIVALENTE DE ARTE MARTÍN MALHARRO</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabPolivalente />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header className='u3'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>INSTITUTO SUPERIOR DE FORMACIÓN DOCENTE "MARIANO MORENO"</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabMMoreno />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header className='u3'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>ECHO VICTOR</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabEchoVictor />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="5">
                            <Accordion.Header className='u3'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>BLAS PASCAL (BELL VILLE - J. POSSE)</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabBPascalBV />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>   
          </div>
         
        </div>
        {/*J POSSE*/}
        <div className="card text-center">
          <div className="card-header head4 text-white" style={{background: "linear-gradient(89.97deg, #16EA5E 1.84%, #0D303C 102.67%)"}}>
            <LottieUni />
            <strong>JUSTINIANO POSSE</strong>
          </div>
            <div className="card-body" style={{background: "linear-gradient(89.97deg, #16EA5E 1.84%, #0D303C 102.67%)"}}>
              
              <div className='col-12'>
                <p>
                  <a class="btn btn-success" data-bs-toggle="collapse" href="#multiCollapseExample4" role="button" aria-expanded="false" aria-controls="multiCollapseExample4">Ver Instituciones</a>
                </p>
                <div class="row">
                  <div class="col-12">
                    <div class="collapse multi-collapse" id="multiCollapseExample4">
                      <div className="col-12">
                        <div class="text-center">
                          <Accordion className='col-12'>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header className='u4'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>INSTITUTO PRIVADO DALMACIO VÉLEZ SÁRSFIELD</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabVelez />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header className='u4'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>CIENCIAMANIA Academia</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabCienciamania />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header className='u4'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>ICIEC J. POSSE</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabIciecJP />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header className='u4'><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>INSTITUTO PRIVADO VIRGEN NIÑA</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabVirgen />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>   
          </div>
          
        </div>
        {/*M BUEY*/}
        <div className="card text-center mb-5">
          <div className="card-header head5 text-white" style={{background: "linear-gradient(89.97deg, #16CFEA 1.84%, #0D303C 102.67%)"}}>
            <LottieUni />
            <strong>MONTE BUEY</strong>
          </div>
            <div className="card-body" style={{background: "linear-gradient(89.97deg, #16CFEA 1.84%, #0D303C 102.67%)"}}>
              
              <div className='col-12'>
                <p>
                  <a class="btn btn-info" data-bs-toggle="collapse" href="#multiCollapseExample5" role="button" aria-expanded="false" aria-controls="multiCollapseExample5">Ver Instituciones</a>
                </p>
                <div class="row">
                  <div class="col-12">
                    <div class="collapse multi-collapse" id="multiCollapseExample5">
                      <div className="col-12">
                        <div class="text-center">
                          <Accordion className='col-12'>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header><FontAwesomeIcon className='m-3' icon={faBuildingColumns}/>CENTRO DE APRENDIZAJE UNIVERSITARIO UNIVERSIDAD SIGLO 21 MONTE BUEY</Accordion.Header>
                            <Accordion.Body>
                              <>
                              <TabSiglo21MB />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>   
          </div>
         
        </div>

      </div>
      <div className='container-fluid bg-white'>
       
        <div className='IMGEXPO text-center mb-5'>

          <img src={imagenMuni} className='imgHeader2' alt='Expo Carreras 2022'></img>
          <img src={imagenExpo} className='imgHeader' alt='Expo Carreras 2022'></img> 
          <img src={imagenForo} className='imgHeader3' alt='Expo Carreras 2022'></img> 
          
        </div>
        </div>
        <div className='contenedor'>
          <p className='text-center text-white m-3 p-3'>Copyright © 2022 <strong>Municipalidad Justiniano Posse - Foro de la Juventud - Rialto Estudio</strong></p>
        </div>
    </div>
    
  )
}

export default Main

