import React,{useState} from 'react'
import Carrera from './Carrera';
import { MDBListGroup, MDBListGroupItem, MDBBadge } from 'mdb-react-ui-kit';
const Polivalente = () => {
  const[carreras, setCarreras] = useState(
    [
        {
            id: 1,
            name: "Profesorado de Educación Primaria",
            años: "-"
        },
        {
          id: 2,
          name: "Profesor/a de Educación Inicial",
          años: "-"
        },
        {
          id: 3,
          name: "Profesor/a de Educación Especial con orientación en Discapacidad Intelectual",
          años: "-"
        },
        {
          id: 4,
          name: "Profesor/a de Educación Secundaria en Biología",
          años: "-"
        },
        {
          id: 5,
          name: "Profesor/a de Educación Secundaria en Historia",
          años: "-"
        },
        {
          id: 6,
          name: "Profesor/a de Educación Secundaria en Matemática",
          años: "-"
        },
        {
          id: 7,
          name: "Profesor/a de Educación Secundaria en Química",
          años: "-"
        },
        {
          id: 8,
          name: "Profesor/a de Música",
          años: "-"
        },
        {
          id: 9,
          name: "Profesor/a de Educación Secundaria en Física",
          años: "-"
        },
        {
          id: 10,
          name: "Tecnicatura Superior en Danzas con Orientación en Danzas Folklóricas Argentinas",
          años: "-"
        },

        
    ]
);

return (
<>
    <div className='row'>
        {carreras.map((carrera) => {
            return(
                <MDBListGroup style={{ minWidth: '22rem' }}>
                    <MDBListGroupItem className='d-flex justify-content-between align-items-center'>
                        
                        <Carrera key={carrera.id} name={carrera.name}/>
                        
                        <MDBBadge pill>{carrera.años} años</MDBBadge>
                    </MDBListGroupItem>
                    
                </MDBListGroup>
            );
        })}
        <a href='https://www.avmartinmalharro.edu.ar/cepla/index.html' className='mt-3'>Sitio Web</a>
    </div>
</>
  
)
}

export default Polivalente